// dependency libraries
import { ReactNode, useEffect, useMemo, useState } from 'react';
// import styled from '@emotion/styled';
import {
  getCookies,
  FloatingAlert,
  useFloatingAlertInfo,
  cx,
  Modal,
  Button,
  TextDS2,
  // ProgressBar,
} from '@hol-jsp/dashboard-dsl';
import { useRouter } from 'next/router';
import { useSideNavInfo } from 'provider/SideNavProvider';
import { useUserInfo } from 'provider/UserProvider';
import SideNav from './SideNav';
import HeaderNav from './HeaderNav';
import { useGetOrgSettings } from 'modules/general/hooks';
import { setCookies } from 'helper/cookiesHelper';
import classnames from 'classnames';
import {
  getCompanyId,
  getLoginType,
  getOrgSettingsFromCookies,
  getUserEntryPoint,
  urlWithSlug,
  usePermission,
} from 'helper';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useSubscription } from '@apollo/react-hooks';
import { LISTEN_EXECUTE_CONTENT } from 'modules/subscription/listen-execute-content';
import { useGetUserProfile } from 'modules/personal-setting/account/hooks';
import { useGetRenewPasswordEligibility } from '@/modules/user/hooks';

export interface LayoutProps {
  children?: ReactNode;
}

export function LoadingImage(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      src="/images/loading-icon-fast.webp"
      alt=""
      width={36}
      height={36}
      {...(props as any)}
    />
  );
}

export function CenteredLoadingImage({ className }: { className?: string }) {
  return (
    <div className={classnames('flex items-center justify-center', className)}>
      <LoadingImage />
    </div>
  );
}

export function LoadingLayout({ className, ...props }: { className?: string }) {
  return (
    <div
      {...props}
      className={classnames(
        className,
        'absolute w-full h-full flex flex-col items-center justify-center'
      )}
    >
      <img src="/images/loading-icon-fast.webp" alt="" width={36} height={36} />
    </div>
  );
}

function Layout({ children }: LayoutProps) {
  // Many lines have been commented out because they are not included in this sprint.

  const { user } = useUserInfo();
  const router = useRouter();
  const { hasPermissions } = usePermission();
  const { t } = useTranslation();
  const pathname = router.asPath;
  const sideNavInfo = useSideNavInfo();
  const orgSettings = getOrgSettingsFromCookies();
  const floatingAlertInfo = useFloatingAlertInfo();
  const query = new URLSearchParams(router.asPath.split(/\?|#/)[1]);
  const token = getCookies('token');
  const companyId = getCompanyId();
  // const isEmpty: boolean = (query.get('is-empty') as string) === 'true';
  // const updateProgress: string = query.get('update-progress') as string;
  // const isFinish: boolean = !!updateProgress && Number(updateProgress) === 100;
  // const isNotFinish: boolean =
  //   !!updateProgress &&
  //   (Number(updateProgress) > 0 || Number(updateProgress) < 100);
  // const isStarted: boolean = !!updateProgress && Number(updateProgress) === 0;
  // const isUpdating = isEmpty || isNotFinish;
  const isUpdating = pathname.split('/').includes('updating-content');
  const isReadyToUpdate: boolean =
    (query.get('ready-to-update') as string) === 'true';
  // const [isOnGoingModalShow, setIsOnGoingModalShow] =
  //   useState<boolean>(isNotFinish);
  // const [isStartUpdateModalShow, setIsStartUpdateModalShow] =
  //   useState<boolean>(isStarted);

  const { data, isLoading } = useGetOrgSettings();
  const { data: userData, isLoading: userDataLoading } = useGetUserProfile();
  const loginType = getLoginType();
  const isStandardLoginType = loginType === 'standard';
  const { data: dataRenewPasswordEligibility } =
    useGetRenewPasswordEligibility(isStandardLoginType);
  const isRenewPasswordEligible =
    dataRenewPasswordEligibility?.data?.data.is_renew_password;

  const { data: dataListenExecuteContent } = useSubscription(
    LISTEN_EXECUTE_CONTENT,
    {
      variables: {
        token,
        company_id: companyId,
      },
      onError: (error) => {
        console.error('Subscription error:', error);
        floatingAlertInfo.dispatch({
          message: 'Subscription error: disconnected with server',
          type: 'error',
        });
      },
    }
  );

  useEffect(() => {
    if (dataListenExecuteContent?.listen_execute_content?.is_loading) {
      router.push(urlWithSlug('/updating-content'));
      return;
    }
    if (dataRenewPasswordEligibility?.data?.data?.is_renew_password) {
      setCookies({
        key: 'renew_password_eligibility',
        value: JSON.stringify(dataRenewPasswordEligibility?.data?.data),
      });
      router.push('/renew-password');
      return;
    }
    if (isRenewPasswordEligible) {
      setCookies({
        key: 'renew_password_eligibility',
        value: JSON.stringify(dataRenewPasswordEligibility?.data?.data),
      });
      router.push('/renew-password');
      return;
    }
  }, [
    dataListenExecuteContent,
    dataRenewPasswordEligibility?.data?.data,
    isRenewPasswordEligible,
    router,
  ]);

  useEffect(() => {
    if (data && data.data?.data) {
      data.data.data['is_username_login'] = true;
      setCookies({
        key: 'org_settings',
        value: JSON.stringify(data.data.data),
      });
    }
  }, [data]);

  useEffect(() => {
    if (!getCookies('token') && !getCookies('slug')) {
      router.push('/login');
      return;
    }
    // if (['true'].includes(getCookies('is_renew_password_eligible')!)) {
    //   router.push('/renew-password');
    //   return;
    // }
  }, [router]);

  const navigation = useMemo(() => {
    return (
      sideNavInfo?.state?.sideNavNavigations?.filter((item) => {
        if (item.childNav) {
          return item.childNav.some((childItem) =>
            hasPermissions(childItem.permissions)
          );
        }

        if (item.permissions) {
          return hasPermissions(item.permissions);
        }

        return true;
      }) || []
    ).map((item) => {
      if (item.childNav) {
        return {
          ...item,
          childNav: item.childNav.filter((childItem) =>
            hasPermissions(childItem.permissions)
          ),
        };
      }

      return item;
    });
  }, [hasPermissions, sideNavInfo?.state?.sideNavNavigations]);

  if (isLoading || !router.isReady || userDataLoading) {
    return <LoadingLayout />;
  }

  // const handleReload = () => router.replace(pathname);

  const handleClose = () => router.replace(pathname);

  return (
    <div>
      {isUpdating ? (
        <>{children}</>
      ) : (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          .theme-button.solid:not([disabled]), .popup-confirm-button.popup-ds:not([disabled]){
            background-color: ${orgSettings?.button_primary_background_color};
            color: ${orgSettings?.button_primary_text_color};
          }
          .theme-button.text:not([disabled]), .popup-cancel-button.popup-ds:not([disabled]) {
            color: ${orgSettings?.button_text_color} !important;
          }
          .theme-button.text:disabled, .popup-cancel-button.popup-ds:disabled {
            background-color: transparent !important;
          }
        `,
            }}
          />
          {user ? (
            <>
              <HeaderNav
              //  isUpdating={isUpdating} updateProgress={updateProgress}
              />
              {!sideNavInfo.state.hideSideNav && (
                <SideNav
                  title={String(sideNavInfo.state.sideNavTitle)}
                  nav={navigation}
                  fullyShown={sideNavInfo.state.show}
                  setFullyShown={() =>
                    sideNavInfo.dispatch({ show: !sideNavInfo.state.show })
                  }
                  testid={sideNavInfo.state.testidSidebar}
                  isUpdating={isUpdating}
                />
              )}
              {floatingAlertInfo.state.message && (
                <FloatingAlert
                  data-testid="swalMessage"
                  message={floatingAlertInfo.state.message}
                  type={floatingAlertInfo.state.type}
                  className="mt-9"
                />
              )}
              <div
                className={cx(
                  'w-full h-full min-h-[100vh] pt-[68px] transition-all ease-in-out duration-500 z-0 bg-neutral-50',
                  sideNavInfo.state.show &&
                    !sideNavInfo.state.hideSideNav &&
                    'pl-[234px]',
                  !sideNavInfo.state.show &&
                    !sideNavInfo.state.hideSideNav &&
                    'pl-[70px]'
                )}
              >
                <div
                  className={cx(
                    'pl-[42px] pb-[24px] pr-[40px] h-full relative',
                    isUpdating ? 'pt-8' : 'pt-4'
                  )}
                >
                  {children}
                </div>
              </div>
            </>
          ) : (
            <LoadingLayout />
          )}
        </>
      )}
      {/* 
      <Modal show={isFinish} size="none">
        <Modal.Body>
          <div className="flex flex-col gap-3 items-center text-center mx-auto py-8 px-9 w-[524px]">
            <Image
              src="/images/lia-success-content.png"
              width={342}
              height={216}
              alt="Success Updating Content"
            />
            <div>
              <TextDS2
                agDesktop="Desktop/Body 2/Semi Bold"
                agMobile="Desktop/Body 2/Semi Bold"
                color="Neutral/400"
              >
                Updating Initialize Content is Done
              </TextDS2>
              <TextDS2
                agDesktop="Desktop/Body 3/Medium"
                agMobile="Desktop/Body 3/Medium"
                color="Neutral/300"
              >
                Updating Initialize Content on your Company RCS is done. You can
                reload this page to view changes.
              </TextDS2>
            </div>
            <Button className="w-[257px] mt-4" onClick={handleReload}>
              Reload Page
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isOnGoingModalShow || isStartUpdateModalShow} size="none">
        <Modal.Body>
          <div className="flex flex-col gap-3 items-center text-center mx-auto py-8 px-9 w-[524px]">
            <Image
              src="/images/lia-on-going.png"
              width={342}
              height={216}
              alt="On Going Updating Content"
            />
            <div>
              <TextDS2
                agDesktop="Desktop/Body 2/Semi Bold"
                agMobile="Desktop/Body 2/Semi Bold"
                color="Neutral/400"
              >
                {isStartUpdateModalShow
                  ? 'New Content is Available'
                  : 'Updating Content is on going'}
              </TextDS2>
              <TextDS2
                agDesktop="Desktop/Body 3/Medium"
                agMobile="Desktop/Body 3/Medium"
                color="Neutral/300"
              >
                {isStartUpdateModalShow
                  ? 'To process the update please select button below. During the update, access to certain menu will be unavailable. The update may take some time to complete.'
                  : 'There is new update on RCS Content at 23 February 2024. You can not access RCS during the updating process until its done. The update will take some time.'}
              </TextDS2>
            </div>
            <Button
              className="w-[257px] mt-4"
              onClick={() =>
                handleClose(isStartUpdateModalShow ? 'start' : 'on-going')
              }
            >
              {isStartUpdateModalShow ? 'Confirm Update Now' : 'Close'}
            </Button>
            {isStartUpdateModalShow && (
              <p className="font-bold mt-6 text-[14px] text-primary-main">
                Confirm Update at 00.00 Next Day
              </p>
            )}
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal show={isReadyToUpdate} size="none">
        <Modal.Body>
          <div className="flex flex-col gap-3 items-center text-center mx-auto py-8 px-9 w-[524px]">
            <Image
              src="/images/lia-on-going.png"
              width={342}
              height={216}
              alt="On Going Updating Content"
            />
            <div>
              <TextDS2
                agDesktop="Desktop/Body 2/Semi Bold"
                agMobile="Desktop/Body 2/Semi Bold"
                color="Neutral/400"
              >
                {t('readyToUpdate.title')}
              </TextDS2>
              <TextDS2
                agDesktop="Desktop/Body 3/Medium"
                agMobile="Desktop/Body 3/Medium"
                color="Neutral/300"
              >
                {t('readyToUpdate.description')}
              </TextDS2>
            </div>
            <Link href={urlWithSlug('/updating-content')}>
              <Button className="w-[257px] mt-4">
                {t('readyToUpdate.buttonConfirmation')}
              </Button>
            </Link>

            <p
              className="font-bold mt-2 text-[14px] text-primary-main cursor-pointer"
              onClick={handleClose}
            >
              {t('readyToUpdate.buttonConfirmationNextDay')}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Layout;

export function LayoutWithoutToken({ children }: { children?: ReactNode }) {
  const [ready, setReady] = useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    const token = getCookies('token');
    if (token) router.push('/');
    else setReady(true);
  }, [router]);
  return ready ? <div>{children}</div> : <LoadingLayout />;
}
